import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../../../utils/contexts/UserContext";
import useAuth from "../../../utils/hooks/useAuth";
import SimpleLoader from "../../../components/Loader/SimpleLoader";
import { postPasswordResetConfirm } from "../../../services/auth/passwordService";
import Input from "../../../components/forms/Input/Input";
import { Button } from "../../../components/forms/Buttons/Button";
import { useForm } from "react-hook-form";
import TokenService from "../../../services/token.service";
import bilAPI from "../../../services/api";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { PASSWORD_RESET_ERROR_CODE } from "../../../utils/constants/constants";
import useSnackbar from "../../../utils/hooks/useSnackbar";

export default function PasswordResetConfirm() {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { addSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [password, setPassword] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [isVisibleConfirm, setIsVisibleConfirm] = useState(false);
  const { token } = useParams();
  const { setUserContext } = useAuth();

  const {
    formState: { errors },
    register,
    handleSubmit,
    watch,
  } = useForm();

  const { user } = useContext(UserContext);

  useEffect(() => {
    setIsLoading(true);
    postPasswordResetConfirm({ token, password })
      .then((res) => {
        TokenService.updateLocalAccessToken(res.access);
        bilAPI.defaults.headers["Authorization"] = "Bearer " + res.access;
        TokenService.updateLocalRefreshToken(res.refresh);
        setUserContext(res.access);
      })
      .catch((err) => {
        if (err?.response?.data?.code === 2) {
          setIsValid(true);
        } else if (err?.response?.data?.code in PASSWORD_RESET_ERROR_CODE) {
          addSnackbar(t(PASSWORD_RESET_ERROR_CODE[err?.response?.data?.code]));
        }
      })
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password, token]);

  useEffect(() => {
    if (user?.uuid) {
      if (user?.roles?.some((role) => role?.code === "ADMIN")) {
        navigate({
          pathname: `/admin/exams`,
          state: { user: user },
        });
      }
      if (user?.roles?.some((role) => role.code === "STD")) {
        navigate({
          pathname: `/student/profile`,
          state: { user: user },
        });
      }
    }

    return () => {};
  }, [user, navigate]);

  const onSubmit = (data) => {
    setPassword(data?.password);
  };

  return (
    <div className="flex flex-col items-center justify-center mt-32">
      {isLoading ? (
        <SimpleLoader className="h-7" />
      ) : isValid ? (
        <div className="flex flex-col gap-2 rounded-md border bg-white p-8">
          <form
            className="flex flex-col gap-2"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="flex flex-row relative">
              <Input
                errorMsg={errors?.password?.message}
                inputClass={errors?.password?.message && "ring-error ring-1"}
                className="w-80"
                label={t("welcome.password")}
                placeholder={t("signup.enter_password")}
                type={isVisible ? "text" : "password"}
                required
                {...register("password", {
                  required: t("signup.is_required_field_error", {
                    field: t("welcome.password")?.toLowerCase(),
                  }),
                  maxLength: 64,
                  validate: (value) => {
                    const errors = [];
                    if (value?.length < 8)
                      errors.push(t("signup.password_error.err_1"));
                    if (!/[A-Z]/.test(value))
                      errors.push(t("signup.password_error.err_2"));
                    if (!/[a-z]/.test(value))
                      errors.push(t("signup.password_error.err_3"));
                    if (!/\d/.test(value))
                      errors.push(t("signup.password_error.err_4"));
                    if (value === watch("email"))
                      errors.push(t("signup.password_error.err_5"));

                    return errors.length > 0 ? errors.join("\n") : true;
                  },
                })}
              />
              <div className="absolute right-2 top-7 cursor-pointer text-primary">
                {isVisible ? (
                  <EyeIcon
                    className="h-5"
                    onClick={() => setIsVisible(false)}
                  />
                ) : (
                  <EyeSlashIcon
                    className="h-5"
                    onClick={() => setIsVisible(true)}
                  />
                )}
              </div>
            </div>
            <div className="flex flex-row relative">
              <Input
                errorMsg={errors?.confirmPassword?.message}
                inputClass={
                  errors?.confirmPassword?.message && "ring-error ring-1"
                }
                className="w-80"
                label={t("welcome.confirm_password")}
                placeholder={t("signup.enter_password")}
                type={isVisibleConfirm ? "text" : "password"}
                required
                {...register("confirmPassword", {
                  required: t("signup.is_required_field_error", {
                    field: t("welcome.confirm_password")?.toLowerCase(),
                  }),
                  maxLength: 64,
                  validate: (value) => {
                    if (value !== watch("password"))
                      return t("signup.confirm_password_error");
                  },
                })}
              />{" "}
              <div className="absolute right-2 top-7 cursor-pointer text-primary">
                {isVisibleConfirm ? (
                  <EyeIcon
                    className="h-5"
                    onClick={() => setIsVisibleConfirm(false)}
                  />
                ) : (
                  <EyeSlashIcon
                    className="h-5"
                    onClick={() => setIsVisibleConfirm(true)}
                  />
                )}
              </div>
            </div>
            <Button
              loading={isLoading}
              type="submit"
              color="primary"
              text={t("signup.reset_password")}
              className="mt-2"
            />
          </form>

          <div className="border-t" />
          <a href="/login" className="text-center text-primary hover:underline">
            {t("welcome.login")}
          </a>
        </div>
      ) : (
        <div className="rounded-2xl border bg-red-200 p-8 mx-8 text-4xl md:text-6xl">
          {t("signup.invalid_or_expired_token")}
        </div>
      )}
    </div>
  );
}
